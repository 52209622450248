<template>
  <page-header-wrapper content="设置角色的区域权限">
    <a-card class="card" :title="roleInfo.roleName" :bordered="false">
      <template>
        <a-form :form="form" class="form">
          <a-row class="form-row" :gutter="16">
            <a-col :lg="8" :md="12" :sm="24">
              <!--a-tree
                :treeData="gridCommunityList"
                :defaultExpandAll="true"
                :checkStrictly="false"
                :multiple="true"
                :selectedKeys="selectedKeys"
                @select="selecttree"
              >
              </a-tree-->
              <a-tree-select
                :tree-default-expand-all="true"
                v-model="selectvalue"
                style="width: 100%;"
                :height="100"
                :tree-data="gridCommunityList"
                tree-checkable
                allow-clear
                :show-checked-strategy="SHOW_PARENT"
                placeholder="请选择区域范围"
                :showSearch="true"
                :max-tag-count="10"
              />
            </a-col>
          </a-row>
          <a-form-item>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="saveRegionPower">设置</a-button>
            </span>
          </a-form-item>
        </a-form>
      </template>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { gridCommunityList, gridHouseList } from '@/api/gridhouse'
import { gridRoleInfo, saveRegionPower } from '@/api/gridrole'
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT

export default {
  name: 'PowerInfo',
  components: {
    STree
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addpower' }),
      loading: false,
      memberLoading: false,
      roleParam: {
        roleId: 0
      },
      roleInfo: {},
      routeList: [],
      checkedKeys: {
        // checked: [],
        // halfChecked: []
      },
      defaultExpandAll: true,
      gridCommunityList: [],
      selectedKeys: [],
      selectvalue: [],
      SHOW_PARENT,
      defaultKey: 1
    }
  },
  created () {
    this.gridCommunityListApi()
    if (this.$route.query.roleId !== 0 && this.$route.query.roleId !== undefined && this.$route.query.roleId !== '') {
      this.roleParam.roleId = parseInt(this.$route.query.roleId)
      this.gridRoleInfoApi()
    }
  },
  methods: {
    gridRoleInfoApi () {
      const _this = this
      gridRoleInfo(_this.roleParam).then((res) => {
        if (res.errorCode === 0) {
          _this.roleInfo = res.result
          _this.selectvalue = res.result.regionPower
          console.log(_this.roleInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gridCommunityListApi () {
      const _this = this
      gridCommunityList().then((res) => {
        if (res.errorCode === 0) {
          _this.gridCommunityList = res.result
          console.log('gridCommunityList', res)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gridHouseListApi () {
      const _this = this
      gridHouseList({ unitId: '' }).then((res) => {
        if (res.errorCode === 0) {
          console.log('gridHouseList', res)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    saveRegionPower () {
      const _this = this
      saveRegionPower({ regionPower: _this.selectvalue, roleId: _this.roleParam.roleId }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success(res.errorMsg)
          console.log('gridHouseList', res)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeTab (v) {
      this.defaultKey = v
    }
  }
}
</script>

<style lang="less" scoped>
  .card{
    margin-bottom: 24px;
  }
  .popover-wrapper {
    /deep/ .antd-pro-pages-forms-style-errorPopover .ant-popover-inner-content {
      min-width: 256px;
      max-height: 290px;
      padding: 0;
      overflow: auto;
    }
  }
  .antd-pro-pages-forms-style-errorIcon {
    user-select: none;
    margin-right: 24px;
    color: #f5222d;
    cursor: pointer;
    i {
          margin-right: 4px;
    }
  }
  .antd-pro-pages-forms-style-errorListItem {
    padding: 8px 16px;
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: #e6f7ff;
    }
    .antd-pro-pages-forms-style-errorIcon {
      float: left;
      margin-top: 4px;
      margin-right: 12px;
      padding-bottom: 22px;
      color: #f5222d;
    }
    .antd-pro-pages-forms-style-errorField {
      margin-top: 2px;
      color: rgba(0,0,0,.45);
      font-size: 12px;
    }
  }
</style>
